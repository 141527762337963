import * as React from "react";

import logo from "../img/logo.svg";
import object1 from "../img/footer-object-1.svg";
import facebook from "../img/social/facebook.svg";
import instagram from "../img/social/instagram.svg";

const Footer = class extends React.Component {
  render() {
    const { data } = this.props;

    return (
      <footer id="kontakt" className="footer v-section v-section--footer">
        <div className="v-section__title v-section__title--light">
          <div className="container v-section__title-container">
            04
            <span className="v-section__title-line" />
            Kontakt
          </div>
        </div>
        <div
          className="content"
          style={{
            paddingTop: "120px",
          }}
        >
          <div
            className="container"
            style={{
              position: "relative",
            }}
          >
            <div style={{ maxWidth: "100vw" }} className="columns">
              <div className="column is-2">
                <img
                  src={logo}
                  alt="Veganiola"
                  style={{
                    width: "150px",
                  }}
                />
              </div>
              <div className="column is-4">
                <div className="v-footer__contact">
                  <div className="v-footer__contact-item">
                    <span style={{ width: "15px" }}>e:</span>
                    <a href={`mailto:${data.email}`}>{data.email}</a>
                  </div>
                  <div className="v-footer__contact-item">
                    <span style={{ width: "15px" }}>t:</span>
                    <a href={`tel:${data.phone}`}>{data.phone}</a>
                  </div>
                  <div className="v-footer__contact-item v-footer__contact-item--address">
                    <span>{data.address}</span>
                  </div>
                </div>
              </div>
              <div className="column is-6">
                <div className="v-footer__info">
                  <span className="v-footer__info-social">ŚLEDŹ NAS:</span>
                  <div className="v-footer__info-social">
                    <a title="instagram" href={data.instagram} target="_blank">
                      <img
                        src={instagram}
                        alt="Instagram"
                        style={{ width: "1em", height: "1em" }}
                        rel="noopener noreferrer"
                      />
                    </a>
                    <a title="facebook" href={data.facebook} target="_blank">
                      <img
                        src={facebook}
                        alt="Facebook"
                        style={{ width: "1em", height: "1em" }}
                        rel="noopener noreferrer"
                      />
                    </a>
                    <span>@veganiola</span>
                  </div>
                  <div className="v-footer__copyright">
                    copyright by veganiola
                  </div>
                </div>
              </div>
            </div>
            <div className="v-footer__object">
              <img src={object1} alt="Object"/>
            </div>
          </div>
        </div>
      </footer>
    );
  }
};

export default Footer;
