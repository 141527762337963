import React from "react";

export const LogoIcon = () => (
  <svg
    id="Group_42"
    data-name="Group 42"
    width="148.123"
    height="143.993"
    viewBox="0 0 148.123 143.993"
  >
    <defs>
      <clipPath id="clip-path">
        <rect
          id="Rectangle_3"
          data-name="Rectangle 3"
          width="148.123"
          height="143.993"
          fill="none"
        />
      </clipPath>
    </defs>
    <path
      id="Path_1"
      data-name="Path 1"
      d="M20.213,194.465,18.62,192.9l3.51-9.073,1.733,1.7L21.452,191.6l6.027-2.52,1.733,1.7Z"
      transform="translate(-7.444 -73.489)"
      fill="#fff"
    />
    <path
      id="Path_2"
      data-name="Path 2"
      d="M41.849,206.323l3.307,2.149-1.025,1.577L40.824,207.9l-.84,1.292,3.778,2.456-1.047,1.61L37.087,209.6l4.84-7.448,5.628,3.659-1.046,1.61-3.778-2.456Z"
      transform="translate(-14.826 -80.815)"
      fill="#fff"
    />
    <g id="Group_2" data-name="Group 2">
      <g id="Group_1" data-name="Group 1" clip-path="url(#clip-path)">
        <path
          id="Path_3"
          data-name="Path 3"
          d="M68.349,225.977l-1.532-.6.308-1.029a3.455,3.455,0,0,1-3.072.137,4.5,4.5,0,0,1-2.739-5.949,4.577,4.577,0,0,1,6.138-2.65,4.233,4.233,0,0,1,2.908,4.738l-2.164-.852a2.026,2.026,0,0,0-1.436-1.983,2.586,2.586,0,0,0-1.883,4.817,2.44,2.44,0,0,0,2.6-.436l-2.36-.957.636-1.616,4.424,1.742Z"
          transform="translate(-24.379 -86.158)"
          fill="#fff"
        />
        <path
          id="Path_4"
          data-name="Path 4"
          d="M90.077,230.207l-.822,1.417-2.322-.372,5.142-8.227,2.091.335,2.3,9.42-2.322-.372-.352-1.605Zm2.622-4.476-1.6,2.733,2.271.364Z"
          transform="translate(-34.753 -89.159)"
          fill="#fff"
        />
        <path
          id="Path_5"
          data-name="Path 5"
          d="M125.081,233.292l-1.7.086-4.5-4.651.249,4.868-2.206.112-.454-8.876,1.684-.086,4.537,4.781-.256-5,2.194-.112Z"
          transform="translate(-46.561 -89.716)"
          fill="#fff"
        />
        <rect
          id="Rectangle_2"
          data-name="Rectangle 2"
          width="2.208"
          height="8.879"
          transform="matrix(0.975, -0.221, 0.221, 0.975, 85.464, 133.439)"
          fill="#fff"
        />
        <path
          id="Path_6"
          data-name="Path 6"
          d="M169.037,217.084a4.706,4.706,0,1,1-6.139-2.424,4.6,4.6,0,0,1,6.139,2.424m-2.058.872a2.473,2.473,0,1,0-1.27,3.335,2.493,2.493,0,0,0,1.27-3.335"
          transform="translate(-63.961 -85.656)"
          fill="#fff"
        />
        <path
          id="Path_7"
          data-name="Path 7"
          d="M193.076,208.862l-5.437,3.738-5.034-7.323,1.82-1.251,3.93,5.718,3.618-2.487Z"
          transform="translate(-73.001 -81.564)"
          fill="#fff"
        />
        <path
          id="Path_8"
          data-name="Path 8"
          d="M208.854,193.458l.686,1.486-1.639,1.687-3.777-8.933,1.474-1.518,9.029,3.527-1.639,1.686-1.515-.634Zm-2.156-4.717,1.316,2.882,1.6-1.649Z"
          transform="translate(-81.604 -74.43)"
          fill="#fff"
        />
        <path
          id="Path_9"
          data-name="Path 9"
          d="M53.864,19.608c3.505,2.033,9.451.9,11.384-3.358,2.035-4.485.27-8.617-2.185-10.089a.015.015,0,0,0-.02,0c-2.3,2.475-5.137,2.864-7,1.676a2.383,2.383,0,0,1-.576-3.5c1.148-1.608,3.587-2.245,6.373-2.366A10.455,10.455,0,0,1,71.28,6.736c.1.168,19.061,38.014,19.061,38.014l.065.056c-1.632,4.886-4.8,12.356-8.912,12.821A2.6,2.6,0,0,1,78.644,55.2c-.122-2.2,1.605-4.478,4.889-5.274a.016.016,0,0,0,.012-.015c-.08-2.864-2.583-6.735-7.918-6.6s-8.415,3.774-8.305,8.03c.122,4.8,3.882,8.44,10.536,8.57,8.15.158,11.539-8.442,13.965-15.691l10.014-28.753c.678-1.96,1.2-3.388,1.7-4.6,0-.005-.454.079-.448.08a15.186,15.186,0,0,0,10.084-1.435,22.487,22.487,0,0,0,8.051-8.093.016.016,0,0,0-.008-.024A17.191,17.191,0,0,0,114.968.012c-5.186-.245-8.559,3.479-10.533,6.66a36.187,36.187,0,0,0-3.809,8.634l-3.389,10a.015.015,0,0,1-.027,0L88.764,8.922s-3.646-6.967,1.909-6.967V1.29s-20.978.185-25.42-.2C59.829.625,54.7,1.36,51.791,6.3c-3.128,5.308-2.08,10.9,2.073,13.311"
          transform="translate(-19.978 0)"
          fill="#fff"
        />
        <path
          id="Path_10"
          data-name="Path 10"
          d="M159.412,24.515c3.02,0,5.421,7.743,5.421,20.128,0,14.008-1.625,18.887-4.26,18.887-3.019,0-5.5-7.664-5.5-20.128,0-13.932,1.7-18.887,4.335-18.887m-22.45,20.437c0,11.764,9.909,19.2,21.833,19.2,15.48,0,24.147-10.372,24.147-21.055,0-11.764-9.827-19.2-21.752-19.2-15.405,0-24.229,10.371-24.229,21.055"
          transform="translate(-54.754 -9.553)"
          fill="#fff"
        />
        <path
          id="Path_11"
          data-name="Path 11"
          d="M206.061,94.207c-.566-4.766-4.706-8.1-11.316-7.311a10.092,10.092,0,0,0-8.254,5.388,13.523,13.523,0,0,0-.909,2.127L177.4,118.244l-8.277-16.064s-3.646-6.966,1.908-6.966v-.665H145.074v.665c1.658.165,4.4.827,6.72,5.142l18.779,37.469.11.1c-1.718,4.828-4.636,11.232-8.443,11.665a2.371,2.371,0,0,1-2.805-2.309c-.07-2.176,1.66-4.4,4.906-5.186-.073-2.863-2.575-6.747-7.918-6.61s-8.416,3.775-8.306,8.03c.122,4.8,3.93,8.5,10.584,8.327,8.512-.218,11.381-9.117,13.9-16.695l.411-1.263H173l13.351-38.631a12.07,12.07,0,0,1,1.854-3.534c1.7-2.188,4.142-3.8,5.881-1.14,1.321,2.016-.583,5.6-4.056,6.545.337,2.844,3.187,6.479,8.494,5.849s8.036-4.537,7.535-8.765"
          transform="translate(-57.997 -34.693)"
          fill="#fff"
        />
        <path
          id="Path_12"
          data-name="Path 12"
          d="M46.283,84.274c0-20.613-30.97-20.109-30.97-34.077,0-4.627,3.2-7.238,7.661-7.238,8.921,0,15.821,9,17.5,18.345h.843l.76-17.42c-7.071,0-12.874-1.764-18.767-1.764C8.331,42.119,0,49.268,0,60.293,0,79.985,31.47,81.667,31.47,94.962c0,4.544-3.112,8.414-10.1,8.414-10.093,0-17.669-9.506-20.446-22.8H.082l.842,21.121c11.278,0,12.285,2.525,21.378,2.525,12.449,0,23.981-7.322,23.981-19.946"
          transform="translate(0 -16.838)"
          fill="#fff"
        />
        <path
          id="Path_13"
          data-name="Path 13"
          d="M104.105,145.317c-2.925,0-5.325-7.425-5.325-19.5,0-13.5,1.649-18.3,4.2-18.3,2.924,0,5.251,7.5,5.251,19.5,0,13.57-1.575,18.3-4.128,18.3m.6-38.394c-14.922,0-23.472,10.047-23.472,20.4,0,11.4,9.6,18.6,21.151,18.6,15,0,23.392-10.047,23.392-20.4,0-11.4-9.519-18.6-21.072-18.6"
          transform="translate(-32.474 -42.745)"
          fill="#fff"
        />
        <path
          id="Path_14"
          data-name="Path 14"
          d="M93.528,166.909c-5.29-1.294-10.427.1-12.15.525a27.9,27.9,0,0,1-5.1-7.2,36.061,36.061,0,0,1-3.428-17.544c.04-.717.136-1.878.242-2.7l-1.043-.09A27.032,27.032,0,0,0,71.858,143c-.172,7.562,1.092,12.622,3.524,17.539a28.351,28.351,0,0,0,5.892,8.046,21.864,21.864,0,0,0,2.932,3.488,19.343,19.343,0,0,0,5.179,3.227,20.786,20.786,0,0,0,5.768,1.638,18.293,18.293,0,0,0,3.638.124,16.634,16.634,0,0,0,7.956-2.709,31.194,31.194,0,0,0-13.219-7.445"
          transform="translate(-28.721 -55.925)"
          fill="#fff"
        />
        <path
          id="Path_15"
          data-name="Path 15"
          d="M51.285,156.87a61.1,61.1,0,0,1-8.632,4.55,27.661,27.661,0,0,0-6.5-4.188c-3.693-1.666-10.329-1.995-16.847,2.669l-.288.3s5.116,6.181,15.5,4.78c6.379-.86,12.924-4.678,17.1-7.186.326-.636-.431-.7-.335-.922"
          transform="translate(-7.604 -62.408)"
          fill="#fff"
        />
      </g>
    </g>
  </svg>
);

export const FacebookIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="11.333"
    height="19.788"
    viewBox="0 0 11.333 19.788"
  >
    <path
      id="Icon_feather-facebook"
      data-name="Icon feather-facebook"
      d="M20.833,3H18.015a4.7,4.7,0,0,0-4.7,4.7v2.818H10.5v3.758h2.818v7.515h3.758V14.273h2.818l.939-3.758H17.076V7.7a.939.939,0,0,1,.939-.939h2.818Z"
      transform="translate(-10 -2.5)"
      fill="#fcf8f8"
    />
  </svg>
);

export const InstagramIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21.333"
    height="21.333"
    viewBox="0 0 21.333 21.333"
  >
    <path
      id="Icon_simple-instagram"
      data-name="Icon simple-instagram"
      d="M10.667,0c-2.9,0-3.26.013-4.4.064A7.865,7.865,0,0,0,3.68.56,5.224,5.224,0,0,0,1.79,1.79,5.2,5.2,0,0,0,.56,3.68a7.842,7.842,0,0,0-.5,2.589C.011,7.407,0,7.769,0,10.667s.013,3.26.064,4.4a7.87,7.87,0,0,0,.5,2.589,5.231,5.231,0,0,0,1.23,1.89,5.216,5.216,0,0,0,1.89,1.23,7.875,7.875,0,0,0,2.589.5c1.138.053,1.5.064,4.4.064s3.26-.013,4.4-.064a7.893,7.893,0,0,0,2.589-.5,5.451,5.451,0,0,0,3.12-3.12,7.87,7.87,0,0,0,.5-2.589c.053-1.138.064-1.5.064-4.4s-.013-3.26-.064-4.4a7.888,7.888,0,0,0-.5-2.589,5.235,5.235,0,0,0-1.23-1.89A5.2,5.2,0,0,0,17.653.56a7.847,7.847,0,0,0-2.589-.5C13.926.011,13.564,0,10.667,0Zm0,1.92c2.847,0,3.187.014,4.311.063a5.877,5.877,0,0,1,1.98.369,3.51,3.51,0,0,1,2.025,2.024,5.889,5.889,0,0,1,.367,1.98c.051,1.125.062,1.463.062,4.311s-.013,3.187-.066,4.311a6,6,0,0,1-.374,1.98,3.387,3.387,0,0,1-.8,1.228,3.328,3.328,0,0,1-1.227.8,5.933,5.933,0,0,1-1.987.367c-1.132.051-1.466.062-4.319.062s-3.188-.013-4.319-.066a6.048,6.048,0,0,1-1.988-.374,3.3,3.3,0,0,1-1.226-.8,3.239,3.239,0,0,1-.8-1.227,6.053,6.053,0,0,1-.373-1.987c-.04-1.12-.054-1.466-.054-4.306s.014-3.188.054-4.321a6.047,6.047,0,0,1,.373-1.986,3.162,3.162,0,0,1,.8-1.228,3.155,3.155,0,0,1,1.226-.8,5.9,5.9,0,0,1,1.974-.374c1.133-.04,1.467-.053,4.319-.053l.04.027Zm0,3.269a5.477,5.477,0,1,0,5.477,5.477A5.477,5.477,0,0,0,10.667,5.189Zm0,9.033a3.556,3.556,0,1,1,3.556-3.556A3.555,3.555,0,0,1,10.667,14.222Zm6.974-9.249a1.28,1.28,0,1,1-1.28-1.279A1.281,1.281,0,0,1,17.641,4.973Z"
      fill="#f5eded"
    />
  </svg>
);

export const TimeIcon = () => (
  <svg
    version="1.0"
    id="Warstwa_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 24.9 25.2"
    enable-background="new 0 0 24.9 25.2"
  >
    <metadata>
      <sfw xmlns="&ns_sfw;">
        <slices></slices>
        <sliceSourceBounds
          bottomLeftOrigin="true"
          height="23.5"
          width="23.5"
          x="260.5"
          y="-581.1"
        ></sliceSourceBounds>
      </sfw>
    </metadata>
    <g id="Icon_feather-clock" transform="translate(-1.5 -1.5)">
      <path
        id="Path_68"
        fill="none"
        stroke="#FFFFFF"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M24.1,14.1
		c0,5.7-4.6,10.3-10.3,10.3S3.6,19.8,3.6,14.1S8.2,3.8,13.9,3.8c0,0,0,0,0,0C19.5,3.8,24.1,8.4,24.1,14.1z"
      />
      <path
        id="Path_69"
        fill="none"
        stroke="#FFFFFF"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M13.9,7.9
		v6.2l4.1,2.1"
      />
    </g>
  </svg>
);

export const PersonIcon = () => (
  <svg
    version="1.0"
    id="Warstwa_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 24.9 25.2"
    enable-background="new 0 0 24.9 25.2"
  >
    <metadata>
      <sfw xmlns="&ns_sfw;">
        <slices></slices>
        <sliceSourceBounds
          bottomLeftOrigin="true"
          height="24.5"
          width="24.5"
          x="260.2"
          y="-581.8"
        ></sliceSourceBounds>
      </sfw>
    </metadata>
    <path
      id="Icon_material-person-outline"
      fill="#FFFFFF"
      d="M12.6,3.4c1.8,0,3.2,1.4,3.2,3.2s-1.4,3.2-3.2,3.2S9.3,8.4,9.3,6.7l0,0
	C9.3,4.9,10.8,3.4,12.6,3.4C12.6,3.4,12.6,3.4,12.6,3.4 M12.6,17.2c4.5,0,9.3,2.2,9.3,3.2v1.7H3.2v-1.7C3.2,19.5,8,17.2,12.6,17.2
	 M12.6,0.5c-3.4,0-6.1,2.7-6.1,6.1s2.7,6.1,6.1,6.1s6.1-2.7,6.1-6.1C18.7,3.3,15.9,0.5,12.6,0.5C12.6,0.5,12.6,0.5,12.6,0.5z
	 M12.6,14.3c-4.1,0-12.2,2.1-12.2,6.1V25h24.5v-4.6C24.8,16.4,16.6,14.3,12.6,14.3z"
    />
  </svg>
);
