import React from "react";
import { graphql, Link, useStaticQuery, navigate } from "gatsby";
import scrollTo from "gatsby-plugin-smoothscroll";
import PropTypes from "prop-types";

import { FacebookIcon, InstagramIcon, LogoIcon } from "./Icons";

const Navbar = class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      navBarActiveClass: "",
      collapsed: false,
    };
  }

  listenToScroll = () => {
    const heightToHideFrom = 40;
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    if (winScroll > heightToHideFrom) {
      if (!this.state.collapsed) {
        this.setState({
          collapsed: true,
          active: false,
          navBarActiveClass: "",
        });
      }
    } else {
      this.setState({
        collapsed: false,
        active: false,
        navBarActiveClass: "",
      });
    }
  };

  goTo = (selector) => () => {
    this.toggleHamburger();
    if (this.props.externalPage) {
      return navigate(`/${selector}`);
    }
    return scrollTo(selector);
  };

  componentDidMount() {
    window.addEventListener("scroll", this.listenToScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.listenToScroll);
  }

  toggleHamburger() {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active,
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState({
              navBarActiveClass: "is-active",
            })
          : this.setState({
              navBarActiveClass: "",
            });
      }
    );
  }

  renderLinks = () => {
    return (
      <>
        <span className="navbar-item" onClick={this.goTo("#o-nas")}>
          O NAS
        </span>
        <span className="navbar-item" onClick={this.goTo("#produkty")}>
          PRODUKTY
        </span>
        <span className="navbar-item" onClick={this.goTo("#przepisy")}>
          PRZEPISY
        </span>
        <span className="navbar-item" onClick={this.goTo("#kontakt")}>
          KONTAKT
        </span>
        {/*<Link*/}
        {/*    className="navbar-item v-navbar__last-item"*/}
        {/*    to="/contact/examples"*/}
        {/*>*/}
        {/*  ZNAJDŹ NAS*/}
        {/*</Link>*/}
      </>
    );
  };

  renderNav = (main = true) => {
    const Tag = main ? "nav" : "div";
    const { data } = this.props;

    return (
      <Tag
        className={`navbar is-transparent v-navbar ${
          this.state.collapsed ? " v-navbar--collapsed" : ""
        } ${this.props.dark ? " v-navbar--dark" : ""}${
          main ? "" : " v-navbar--simplified"
        }`}
        role="navigation"
        aria-label={main ? "main-navigation" : ""}
      >
        <div className="container">
          <div className="navbar-brand">
            {main && (
              <Link
                to="/"
                className="navbar-item v-navbar__logo-wrapper"
                title="Logo"
              >
                <div className="v-navbar__logo">
                  <LogoIcon />
                </div>
              </Link>
            )}
            {/* Hamburger menu */}
            <div
              className={`navbar-burger burger ${this.state.navBarActiveClass}`}
              data-target="navMenu"
              role="menuitem"
              tabIndex={0}
              onKeyPress={() => this.toggleHamburger()}
              onClick={() => this.toggleHamburger()}
            >
              <span />
              <span />
              <span />
            </div>
          </div>
          <div
            id="navMenu"
            className={`navbar-menu ${this.state.navBarActiveClass}`}
          >
            <div className="navbar-end">
              {this.renderLinks()}
              <a
                className="navbar-item v-navbar-social"
                href={data.instagram}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="icon">
                  <InstagramIcon />
                </span>
              </a>
              <a
                className="navbar-item v-navbar-social"
                href={data.facebook}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="icon">
                  <FacebookIcon />
                </span>
              </a>
            </div>
          </div>
        </div>
      </Tag>
    );
  };

  render() {
    return (
      <div>
        {this.renderNav()}
        {this.renderNav(false)}
      </div>
    );
  }
};

export default function NavbarDefault({ dark = false, externalPage = false }) {
  const data = useStaticQuery(graphql`
    query {
      markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
        frontmatter {
          info {
            instagram
            facebook
          }
        }
      }
    }
  `);

  const info = data?.markdownRemark?.frontmatter?.info;

  return <Navbar data={info} dark={dark} externalPage={externalPage} />;
}

NavbarDefault.propTypes = {
  externalPage: PropTypes.bool,
  dark: PropTypes.bool,
};
